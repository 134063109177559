[
  {
    "name": "Природен парк \"Русенски Лом\"",
    "type": "tourism",
    "location": "PX3G+MF Иваново",
    "position": "43.704188,25.976188"
  },
  {
    "name": "Пещера \"Орлова чука\"",
    "type": "tourism",
    "location": "HXV6+94 Русе",
    "position": "43.593438,25.960313"
  },
  {
    "name": "Панорамна гледка Божичен",
    "type": "tourism",
    "location": "PX97+X2, 7089 Божичен",
    "position": "43.719938,25.962562"
  },
  {
    "name": "Kомплекс Манастира",
    "type": "guest_house",
    "location": "MXM4+8V Иваново",
    "position": "43.683312,25.957187"
  },
  {
    "name": "Kъща за гости",
    "type": "guest_house",
    "location": "MXQ4+3M Иваново",
    "position": "43.687687,25.956688"
  },
  {
    "name": "Къща за гости \"Кладенеца\"",
    "type": "guest_house",
    "location": "MXM4+HG Иваново",
    "position": "43.683937,25.956313"
  },
  {
    "name": "Семеен хотел Валдес",
    "type": "guest_house",
    "location": "PX93+82 Божичен",
    "position": "43.718312,25.952562"
  },
  {
    "name": "Къща за гости \"Милковата къща\"",
    "type": "guest_house",
    "location": "MX6Q+R4 Кошов",
    "position": "43.662063,25.987812"
  },
  {
    "name": "Malavi Krasen къща за гости",
    "type": "guest_house",
    "location": "PXVM+J6 Красен",
    "position": "43.744062,25.983063"
  },
  {
    "name": "Биби Ойл",
    "type": "gas_station",
    "location": "MXV2+5J Иваново",
    "position": "43.692937,25.951563"
  },
  {
    "name": "Бодуров Ойл",
    "type": "gas_station",
    "location": "PVJ9+VX Пиргово",
    "position": "43.732188,25.869937"
  },
  {
    "name": "Берчелини",
    "type": "gas_station",
    "location": "P2CV+G4 Щръклево",
    "position": "43.721312,26.042813"
  },
  {
    "name": "Газстанция ТИС-МИНЧЕВ",
    "type": "gas_station",
    "location": "HVP8+G3 Две могили",
    "position": "43.586312,25.865187"
  },
  {
    "name": "Винарски комплекс \"Седем поколения\"",
    "type": "restaurant",
    "location": "PR67+7X Мечка",
    "position": "43.710687,25.814937"
  },
  {
    "name": "Ресторант Green House",
    "type": "restaurant",
    "location": "PVM4+MR Пиргово",
    "position": "43.734188,25.857062"
  },
  {
    "name": "Мотел Ханчето",
    "type": "restaurant",
    "location": "Q2H8+R7 Русе",
    "position": "43.779562,26.015687"
  }
]