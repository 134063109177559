import { render, h } from 'preact'
import Glide from '@glidejs/glide'
import Locations from './locations'

const ISC_PLACEID = "ChIJf5oM8F_xrkAR1UOpC_QTdAM",
  ISC_POS = { lat: 43.694878660302116, lng: 25.98698313811894 };

let map: google.maps.Map,
  routeService: google.maps.DirectionsService,
  routeRenderer: google.maps.DirectionsRenderer,
  autocomplete: google.maps.places.Autocomplete,
  geocoder: google.maps.Geocoder,
  poiInfoWin: google.maps.InfoWindow;

let startLocation: google.maps.LatLng | google.maps.LatLngLiteral;

let tbtOpen = false;

//

window.addEventListener('DOMContentLoaded',
  async () => {

    // Directions CTA button
    document.getElementById("get-directions")?.addEventListener('click',
      () => window.location.assign('#directions')
    )

    // Geolocation click event
    document.getElementById("find-me")?.addEventListener('click', e => {
      e.preventDefault();

      if (map) {
        getCurrentLocation();
      }
    })

    // Turn-by-turn expand/close
    document.querySelector("#turn-by-turn .tbt-action")?.addEventListener('click', e => {
      e.preventDefault();

      tbtOpen = !tbtOpen;
      toggleTBTPanel(tbtOpen);
    })

    // Init Google Map
    await initMap();

    // Add address autocomplete
    const ac_input = document.getElementById("start-location")! as HTMLInputElement

    autocomplete = new google.maps.places.Autocomplete(ac_input, {
      fields: ['formatted_address', 'geometry']
    })

    autocomplete.addListener('place_changed', () => {
      console.log("location selected, arguments", autocomplete.getPlace())
      const place = autocomplete.getPlace()

      if (!place.geometry?.location) {
        console.warn("Invalid starting location")
        return;
      }

      startLocation = place.geometry.location

      getDirections();
    })

    // Locations component
    render(h(Locations, undefined), document.getElementById('locations'))

    // Image slider
    new Glide('.glide', {
      type: "carousel",
      perView: 1,
      focusAt: 'center',
      autoplay: 5000
    }).mount()
  }
)

window.addEventListener('POILocationRequested', (e: CustomEvent) => {
  // console.log('handle custom event', e);

  if (map) {
    if (!poiInfoWin) {
      poiInfoWin = new google.maps.InfoWindow();
    }

    const { name, position } = e.detail;
    poiInfoWin.setPosition(position as google.maps.LatLngLiteral);
    poiInfoWin.setContent(name);

    poiInfoWin.open(map);
    
    map.setCenter(position);
    map.setZoom(12);

    map.getDiv().scrollIntoView();
  }
})

//

function getCurrentLocation() {
  const handleError = (hasGeo: boolean, error?: GeolocationPositionError) => {
    const infoWindow = new google.maps.InfoWindow()

    infoWindow.setPosition(map.getCenter()!)
    infoWindow.setContent(hasGeo ?
      'Определянето на текуща локация не успя' :
      'Текущият браузър не поддържа геолокация')

    // TODO: Switch on error.code
    // ...

    infoWindow.open(map)
  }

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      const pos = new google.maps.LatLng({ lat: position.coords.latitude, lng: position.coords.longitude });

      startLocation = pos;

      getDirections();

      // Reverse geocode LatLng to readable address

      if (false) {
        if (!geocoder) {
          geocoder = new google.maps.Geocoder();
        }

        let humanAddr = `${pos.lat}, ${pos.lng}`;;

        geocoder.geocode({ location: pos }).then((response) => {
          if (response.results[0]) {
            humanAddr = response.results[0].formatted_address;
            console.log(response.results[0])
          }
        }).catch((e) => {
          console.error("Reverse geocoding failed, reason", e)
        });

        console.log(humanAddr);

        // DEBUG
        // (document.getElementById("start-location")! as HTMLInputElement).value = humanAddr;
      }
    },
      (error: GeolocationPositionError) => {
        handleError(true, error)
      })
  } else {
    handleError(false)
  }
}

function setTBTVisibility(visible: boolean) {
  document.getElementById("turn-by-turn")?.classList.toggle('hidden', !visible);
}

function toggleTBTPanel(open: boolean) {
  const tbtRoot = document.getElementById("turn-by-turn"), tbtAction = tbtRoot?.querySelector('.tbt-action');

  tbtRoot?.classList.toggle('closed', !open);

  if (tbtAction) {
    (tbtAction as HTMLElement).innerText = open ? "Скрий" : "Покажи";
  }
}

//

function getDirections() {
  if (!startLocation) {
    console.error("Unknown start location")
    return;
  }

  const iscPlace: google.maps.Place = {
    placeId: ISC_PLACEID,
  }

  const routeRequest = {
    origin: startLocation,
    destination: iscPlace,
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.METRIC
  }

  const handleError = (status: google.maps.DirectionsStatus) => {
    const infoWindow = new google.maps.InfoWindow()

    infoWindow.setPosition(map.getCenter()!)
    infoWindow.setContent(`Възникна грешка при търсенето на маршрут, код: ${status}`)
    infoWindow.open(map)
  }

  if (!routeService || !routeRenderer) {
    routeService = new google.maps.DirectionsService();
    routeRenderer = new google.maps.DirectionsRenderer();

    routeRenderer.setMap(map)
    routeRenderer.setPanel(document.getElementById("map-panel"))
  }

  routeService.route(routeRequest, (result, status) => {
    console.log('Directions status', status)

    if (status === 'OK') {
      routeRenderer.setDirections(result)

      setTBTVisibility(true)

      console.log(result)
    } else {
      handleError(status)
    }
  })
}

// Google Maps Integration

async function initMap() {
  // Find DOM elements

  const root = document.getElementById("map")!,
    panel = document.getElementById('map-panel')!

  // Load libraries

  const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary
  const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary
  const { Autocomplete } = await google.maps.importLibrary('places') as google.maps.PlacesLibrary

  // Create map

  map = new Map(root, {
    mapId: "Ivanovo-ISC",
    center: { lat: 43.6970354, lng: 25.982736 },
    zoom: 10,

    // styles: mapStyles,

    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    rotateControl: false,
  })

  // Create custom marker

  const marker = new AdvancedMarkerElement({
    map: map,
    position: ISC_POS,
    collisionBehavior: google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
  })

  // Update marker visibility

  map.addListener('zoom_changed', () => {
    const zoom = map.getZoom();

    if (zoom && zoom < 13) {
      marker.map = map;
    } else {
      marker.map = null;
    }
  })
}