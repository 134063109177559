import * as data from '../assets/locations.json'
import { useState } from 'preact/hooks'

const TYPE_STRINGS = {
  all: "Всички",
  tourism: "Туризъм",
  guest_house: "Къща за гости",
  restaurant: "Ресторант",
  gas_station: "Бензиностанция",
};

const TYPE_IMAGES = {
  all: new URL('../assets/icons/icons8-globe-64.png', import.meta.url),
  tourism: new URL('../assets/icons/icons8-camera-64.png', import.meta.url),
  guest_house: new URL('../assets/icons/icons8-cabin-64.png', import.meta.url),
  restaurant: new URL('../assets/icons/icons8-cutlery-64.png', import.meta.url),
  gas_station: new URL('../assets/icons/icons8-gas-pump-64.png', import.meta.url),
}

const map_image = new URL('../assets/icons/icons8-map-64.png', import.meta.url);

const nameForType = (type: string) => TYPE_STRINGS[type] || "?";
const pluscodeLocality = (pc: string) => pc.split(' ').at(-1) || "?"
const imgUrlForType = (type: string) => new URL('../assets/icons/icons8-globe-64.png', import.meta.url);

function showOnMap(name: string, coords: string) {
  // console.log('showOnMap', pluscode);

  const position = coords.split(',').reduce((result, s, i) => {
    result[['lat', 'lng'][i]] = parseFloat(s);
    return result;
  }, {});

  window.dispatchEvent(new CustomEvent('POILocationRequested', {
    bubbles: false,
    detail: { name, position }
  }))
}

export default () => {
  const [fType, setFType] = useState('all');

  return (<>
    <div id="locations-filter">
      {Object.entries(TYPE_STRINGS).map(([type, name]) =>
        <a href="#" class={`type-${type}`} key={type} onClick={e => {
          e.preventDefault();
          setFType(type);
        }}>
          <img class={'icon small'} src={TYPE_IMAGES[type]} />
          {name}
        </a>
      )}
    </div>
    <div id="location-entries">
      {data
        .filter((entry => fType === 'all' || fType === entry.type))
        .map((entry, index) => (
          <div class="location-entry" key={`poi-${index}`}>
            <div className={`location-type type-${entry.type}`}>{nameForType(entry.type)}</div>
            <div className="location-name">{entry.name}</div>
            <div className="locality">{pluscodeLocality(entry.location)}</div>
            <div className="show-on-map"><a href="#" onClick={e => {
              e.preventDefault();
              showOnMap(entry.name, entry.position)
            }}><img class={'icon'} align="right" src={map_image} /></a></div>
          </div>
        ))
      }
    </div>
  </>)
}
